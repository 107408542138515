import React from 'react'
import { Link } from 'react-router-dom'

const TeamAll = () => {
    return (
        <div className="team-area-1 space">
            <div className="container">
                <div className="row gy-30 gx-30">
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-1.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Darrell Steward</Link>
                                </h4>
                                <span className="team-card_desig">Human Resource</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-2.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Fahad Hossain</Link>
                                </h4>
                                <span className="team-card_desig">Efficiency Experts</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-3.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Javed Tanvir</Link>
                                </h4>
                                <span className="team-card_desig">Strategic Solutions Pro</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-4.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Nafiz Islam</Link>
                                </h4>
                                <span className="team-card_desig">Growth Catalyst</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-1.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Darrell Steward</Link>
                                </h4>
                                <span className="team-card_desig">Human Resource</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src="assets/img/team/team-1-2.png" alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Digious Solutions" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Digious Solutions" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link to="/team-details">Javed Tanvir</Link>
                                </h4>
                                <span className="team-card_desig">Strategic Solutions Pro</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TeamAll