import React from "react";
import { Link } from "react-router-dom";

const BlogDetailsInnerNFT = () => {
  return (
    <section className="blog-area space-top space-extra-bottom">
      <div className="container">
        <div className="row gx-30">
          <div className="col-xxl-8 col-lg-7">
            <div className="blog-details-card">
              <div className="blog-meta">
                <Link to="/blog">
                  <i className="fas fa-user" />
                  By admin
                </Link>
                <Link to="/blog">
                  <i className="fas fa-folder-open" />
                  Category
                </Link>
                <Link to="/blog">
                  <i className="fas fa-comments" />
                  Comments (05)
                </Link>
              </div>
              <h2 className="blog-title">What is NFT Art?</h2>
              {/* <div className="blog-thumb">
                                <img src="/assets/img/blog/stranger-apple-id-featured.jpg" alt="Digious Solutions" />
                            </div> */}
              <div className="blog-content">
                <h3 className="blog-inner-title">
                  Exploring the World of NFT Art: Beyond Digital Ownership
                </h3>
                <p className="mt-n2 mb-15">
                  NFT Art is digital artwork bought and sold using a special
                  digital token called an NFT, which stands for “Non-Fungible
                  Token.” Think of an NFT as a unique digital certificate that
                  proves you own a digital item, like an artwork. Unlike regular
                  digital files that can be copied many times, an NFT is
                  one-of-a-kind, or limited in number, making it more valuable.
                  It’s like having a signed copy of a painting in the digital
                  world. So, when you buy NFT Art, you get a special token that
                  says you own a unique piece of digital art.
                </p>
                <h3 className="blog-inner-title">Democratization of Art</h3>
                <p className="mt-20">
                  A significant aspect of NFT art is its ability to democratize
                  the art world. Artists can now connect directly with
                  collectors, bypassing traditional gatekeepers like galleries.
                  Notably, NFTs enable artists to receive royalties
                  automatically through smart contracts, a game-changer compared
                  to conventional art sales.
                </p>
                <h3 className="blog-inner-title">Environmental Impact</h3>
                <p className="mt-20">
                  The environmental footprint of NFTs, particularly those on the
                  energy-intensive Ethereum blockchain, is a topic that often
                  takes a backseat. The proof-of-work system used by Ethereum
                  has raised concerns about the carbon footprint of NFTs.
                  However, the anticipated shift to a more eco-friendly
                  proof-of-stake system offers a glimpse of hope.
                </p>
                <h3 className="mt-4 blog-inner-title">
                  Community and Engagement
                </h3>
                <p>
                  NFT art isn’t solely about the artwork but also about
                  fostering a sense of community. Many NFT projects involve
                  interactive elements, encouraging holders to participate in
                  decision-making or offering exclusive access to events. This
                  facet of NFT art often escapes mainstream discussion.
                </p>
                <h3 className="mt-4 blog-inner-title">
                  Preserving Digital Heritage
                </h3>
                <p>
                  An often overlooked yet crucial aspect of NFTs is their role
                  in preserving digital art. As digital creation becomes
                  increasingly prominent, NFTs offer a way to ensure that these
                  artworks have a verifiable history, potentially safeguarding
                  digital heritage for future generations.
                </p>
                <h3 className="mt-4 blog-inner-title">In Summary</h3>
                <p>
                  NFT art is revolutionizing how we perceive digital creativity
                  and ownership. From challenging traditional art markets,
                  raising environmental awareness, fostering community
                  involvement, and preserving digital heritage, NFTs are more
                  than just a passing trend. They represent a new chapter in the
                  evolving narrative of art and technology.
                </p>
                <hr className="its_divider"></hr>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-5">
            <aside className="sidebar-area">
              <div className="widget widget_search">
                <h3 className="widget_title">Search Here</h3>
                <form className="search-form">
                  <input type="text" placeholder="Search.." />
                  <button type="submit">
                    <i className="fas fa-search" />
                  </button>
                </form>
              </div>

              <div className="widget">
                <h3 className="widget_title">Latest Blog</h3>
                <div className="recent-post-wrap">
                  <div className="recent-post">
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <img
                          src="/assets/img/icon/calender.svg"
                          alt="Digious Solutions"
                        />
                        Jan 10, 2024
                      </Link>
                    </div>
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Navigating Global Trade One Shipmen
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="recent-post">
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <img
                          src="/assets/img/icon/calender.svg"
                          alt="Digious Solutions"
                        />
                        Jan 10, 2024
                      </Link>
                    </div>
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Strategic Solutions for the a Business Growth
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="recent-post">
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <img
                          src="/assets/img/icon/calender.svg"
                          alt="Digious Solutions"
                        />
                        Jan 10, 2024
                      </Link>
                    </div>
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Achieve Your Business Goals with Confidence
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsInnerNFT;
