import React from "react";

const WhyChoose = () => {
  return (
    <div className="wcu-area-2 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div data-aos="fade-up" data-aos-delay="500" className="title-area text-center me-xl-4 ms-xl-4">
              <span className="sub-title">
                <img src="/assets/img/icon/title_left.svg" alt="shape" />
                Digious Solutions: 
              </span>
              <h2 className="sec-title style2">
              Innovative IT and Marketing Excellence Worldwide
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6">
            <div className="wcu-thumb">
              <div className="wcu-thumb-1">
                <img src="/assets/img/normal/worldwide.png" alt="img" />
              </div>
              <div className="wcu-shape jump">
                <img src="/assets/img/normal/1.png" alt="img" />
              </div>
              <div className="wcu-shape2 movingX">
                <img src="/assets/img/normal/2.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="wcu-card-wrap">
              <div data-aos="fade-up" data-aos-delay="500" className="wcu-card">
                <div className="icon">
                  <img src="/assets/img/icon/wcu-icon_2-1.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                  Global Services
                  </h4>
                  <p className="wcu-card-text">
                  Offering IT and marketing solutions worldwide.{" "}
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="600" className="wcu-card">
                <div className="icon">
                  <img src="/assets/img/icon/wcu-icon_2-2.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                  Skilled Team
                  </h4>
                  <p className="wcu-card-text">
                  Expert designers, developers, and marketing specialists.{" "}
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="700" className="wcu-card">
                <div className="icon">
                  <img src="/assets/img/icon/wcu-icon_2-3.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">
                  Modern Techniques
                  </h4>
                  <p className="wcu-card-text">
                  Utilizing updated codes and tech trends.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="800" className="wcu-card">
                <div className="icon">
                  <img src="/assets/img/icon/wcu-icon_2-4.svg" alt="img" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Customer Satisfaction </h4>
                  <p className="wcu-card-text">
                  Ensuring client fulfilment as our priority.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
